import React from "react";
import cx from "classnames";
import home from "../images/undraw_getting_coffee_wntr.svg";
import suffer from "../images/noun_depressed_2839144.svg";
import growing from "../images/noun_growing business_1895490.svg";
import accident from "../images/noun_Accident_2703749.svg";
import group_child from "../images/noun_team_2272037.svg";
import personnal_dvpt from "../images/noun_Independent Child_1578737.svg";
import parenting from "../images/noun_child care_36686.svg";


import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-lblue hover:bg-lblue text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2 border-r border-gray-400">
      <Link
        to={url}
        className="text-2xl text-lblue hover:text-lblue hover:underline text-center"
      >
        <h3>{title}</h3>
      </Link>
      <p className="text-justify">{children}</p>
    </div>
  );
};

function Index({ data }) {
  const services = data.services.edges;

  return (
    <Layout headerClass="relative bg-white">
      <SEO title="Psychothérapie et Coaching à Troyes"/>
      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        style={{ backgroundImage: `url(${home})` }}
      >
        <div className="w-4/5 md:w-3/4 lg:w-7/12 mt-20 font-serif font-hairline self-start bg-gray-100 rounded p-4"
        style={{
          boxShadow:
            "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
        }}
        >
          <h1 className="text-3xl md:text-5xl text-lblue leading-tight">
            Psychothérapie et Coaching à Troyes
          </h1>
          <p className="text-xl fontWeight:bold text-lgreen">
            Accompagnement psychologique des bébés, des enfants, des adolescents et des adultes à Troyes. Je reçois en individuel, en couple ou lors d'entretiens familiaux.
          </p>
        </div>

        <div className="container w-3/4 sm:w-7/12 lg:w-5/12 xl:1/4 mt-10 self-start">
          <div
            className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
            style={{
              boxShadow:
                "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
            }}
          >
            <div className="flex-1">
              <p>
                <strong>Téléphone: </strong>
                03-10-94-04-13
              </p>
              <p>
                <strong>Email: </strong>
                cedric@le-hir.name
              </p>
            </div>
            <div className="flex-initial mt-6 sm:mt-0">
              <Link to="/contact">
                <StyledButton>Contact</StyledButton>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:mt-16"
        style={{
          boxShadow:
            "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
        }}
        >
          <h2 className="mb-2 text-4xl text-gray-800 self-center">
            Services proposés
          </h2>

          <div className="flex flex-wrap">
            {services.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
              >
                {node.excerpt}
              </Service>
            ))}
          </div>

        </div>

        <div className="flex flex-col mt-10 md:mt-16"
        style={{
          boxShadow:
            "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
        }}>
          <p className="mb-2 text-4xl text-gray-800 self-center">
            Le praticien : Cédric LE HIR
          </p>
          <p className="p-3">
          Je conçois l'accompagnement psychologique comme une <strong>rencontre humaine</strong> entre deux personnes. Cela implique un <strong>engagement émotionnel</strong> de ma part et de la votre. Cet engagement sera mis <strong>au service de la relation</strong> que nous allons tisser ensemble et de <strong>vos besoins spécifiques</strong>.

          <br />

          Par ailleurs, il ne s'agit pas d'une relation où l'un serait "sain" et l'autre "malade". Ayant fait une <strong>psychothérapie approfondie</strong> depuis plusieurs années, j'ai avancé sur mon chemin vers le bonheur. Il s'agit de vous accompagne sur <strong>votre chemin</strong> qui sera dans tous les cas différent du mien. Il est donc important pour moi qu'il n'y est <strong>aucun jugement</strong> dans l'espace de la psychothérapie. Chacun fait les <strong>meilleurs choix possibles</strong> compte tenu des informations dont ils disposent. Et ce choix a donc obligatoirement une <strong>intention positive</strong> au regard de votre fonctionnement ou de votre histoire par exemple. Se libérer de vos fonctionnements limitants et ouvrir sur <strong>plus de choix</strong> est un des objectif possible du travail thérapeutique.

          <br />

          Ainsi, une valeur essentielle pour moi dans l'accompagnement est le <strong>respect</strong>. Le <strong>respect de vos choix</strong>, ceux que vous faites pour parcourir votre chemin. Le <strong>respect du temps</strong> qui vous est nécessaire pour le parcourir. Et enfin le <strong>respect de vos valeurs</strong>, de vos croyances et de votre "carte du monde". Cela implique qu'il me parait le plus souvent contre-productif de vous donner des <strong>conseils</strong> qui ne seront que le résultat de ma vision de la situation et qui ne vous correspondront probablement pas. De plus, comme je crois fermement que chacun a en lui toutes les <strong>ressources</strong> dont il a besoin pour <strong>se développer</strong> et faire les <strong>changements qu'il désire</strong>, il est plus aidant à long terme de stimuler ces <strong>ressources</strong>. Un autre objectif de travail peut être de vous aider à passer une période difficile de votre vie par un <strong>étayage sur les ressources du thérapeute</strong> puis à votre rythme, vous aider à <strong>découvrir les votres</strong> pour retrouver une plus grande <strong>autonomie et liberté</strong>.

          <br />

          Je suis donc heureux de pouvoir rencontrer de nouvelles personnes. Ainsi nous pourrons ensemble apprendre à être <strong>plus présent</strong>. Plus présent à vous-même, aux autres et ainsi aller vers davantage de <strong>joie au quotidien</strong>.

          </p>
        </div>

        <div className="flex flex-col mt-10 md:mt-16"
        style={{
          boxShadow:
            "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
        }}>
          <h2 className="mb-2 text-4xl text-gray-800 self-center">
            Déroulement d'un accompagnement psychologique
          </h2>
          <p className="p-3">
          J'accorde une importance capitale au lien et à l'<strong>alliance thérapeutique</strong> qui va se nouer avec le patient.
          Ainsi, le premier rendez-vous est <strong>gratuit</strong>.
          Il permet de faire connaissance et d'évaluer si un lien sécurisant va pouvoir se créer entre le patient et le thérapeute.
          Nous échangerons également sur votre demande, vos <strong>difficultés psychologiques</strong> et votre besoin d'accompagnement.
          Cela me permet de savoir si je suis la bonne personne pour vous aider à avancer vers plus de <strong>bien-être psychologique</strong>.
          C'est lors de ce rendez-vous que sera discuté le cadre du travail (tarif, engagement, confidentialité...).

          <br/>

          Une fois que nous avons décidés de nous engager dans un travail psychologique, vous décidez avec mon aide de ce que vous souhaitez vous donner lors de cet accompagnement.

          <br/>

          Avec les <strong>enfants</strong> et les <strong>adolescents</strong>, ils sont reçus seuls et nous utilisons les outils leur permettant de mettre en mot ou en acte par le jeu les <strong>difficultés psychologiques</strong> rencontrées.
          Nous pouvons ainsi utiliser de la pate à modeler, des légos, de jeux de société, des dessins, etc.
          Le respect de la confidentialité des séances par les parents permet de favoriser l'évolution de l'<strong>enfant</strong> ou de l'<strong>adolescent</strong>.
          Cependant l'alliance avec les parents étant essentielle au travail avec l'<strong>enfant</strong>, des points seront faits régulièrement avec la <strong>famille</strong>.

          <br/>

          Avec les <strong>adolescents</strong> et les <strong>adultes</strong>, nous travaillons le plus souvent à dénouer les noeuds à l'origine de la <strong>souffrance psychologique</strong> en en parlant.
          Par le questionnement et d'autres techniques de <strong>PNL</strong>, d'<strong>hypnose</strong> et de <strong>systémie</strong>, vous pouvez cheminer vers un plus grand <strong>confort psychologique</strong>.
          Des exercices dans l'espace du cabinet ou en changeant de fauteuils peuvent être proposés pour vous aider à atteindre un mieux-être psychologique.

          <br/>

          A tout moment, vous pouvez me faire part de votre souhait d'interrompre le travail. Une séance permettra de cloturer l'<strong>accompagnement psychologique</strong>.


          </p>
        </div>

        <div className="flex flex-col mt-10 md:mt-16">
          <h2 className="mb-2 text-4xl text-gray-800 self-center">
            Accompagnements proposés
          </h2>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Développement personnel" src={personnal_dvpt} />
                </div>
                <h3 className="text-2xl w-full">Développement personnel</h3>
                <p>
                    <ul>
                        <li>Confiance en soi</li>
                        <li>Relations conflictuelles</li>
                        <li>Gestion du stress</li>
                        <li>Soutien face à un changement</li>
                        <li>Sortir de sa zone de confort</li>
                        <li>Evolution professionnelle</li>
                    </ul>
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Soutien à la parentalité" src={parenting} />
                </div>
                <h3 className="text-2xl w-full"><Link to="/groupes/soutien-parentalite">Soutien à la parentalité</Link></h3>
                <p>
                    <ul>
                        <li>(Re)Créer du lien avec ses enfants</li>
                        <li>Poser des limites avec bienveillance</li>
                        <li>Améliorer l'harmonie familiale</li>
                        <li>Familles recomposées</li>
                        <li>Jeux et écrans</li>
                        <li>Comprendre l'adolescence</li>
                    </ul>
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Groupe enfants adolescents" src={group_child} />
                </div>
                <h3 className="text-2xl w-full">
                  <Link to="/groupe-enfant-adolescent/estime-de-soi">
                    Groupes estime de soi enfants - adolescents
                  </Link>
                </h3>
                <p>
                    <ul>
                        <li>Amélioration de l'estime de soi</li>
                        <li>Gestion des émotions</li>
                        <li>Se faire des amis</li>
                        <li>S'affirmer positivement</li>
                        <li>Faire face aux difficultés de la vie</li>
                        <li>Prévention du harcèlement</li>
                    </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Souffrance" src={suffer} />
                </div>
                <h3 className="text-2xl w-full">Souffrance psychologique</h3>
                <p>
                  <ul>
                      <li>Anxiété</li>
                      <li>Depression</li>
                      <li>Isolement social</li>
                      <li>Addictions</li>
                      <li>Idées noires</li>
                  </ul>
                  </p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Accident" src={accident} />
                </div>
                <h3 className="text-2xl w-full">Accidents de la vie</h3>
                <p>
                    <ul>
                        <li>Stress post-traumatique</li>
                        <li>Deuil</li>
                        <li>Conflits familiaux et de couple</li>
                        <li>Handicap et maladies</li>
                        <li>Harcèlement</li>
                    </ul>
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="Grandir" src={growing} />
                </div>
                <h3 className="text-2xl w-full">Difficultés à grandir</h3>
                <p>
                    <ul>
                        <li>Hyperactivité</li>
                        <li>Haut potentiel intellectuel</li>
                        <li>Hypersensibilité</li>
                        <li>Difficultés de sommeil et d'alimentation</li>
                        <li>Anorexie/Boulimie</li>
                    </ul>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" }, frontmatter: {draft: {eq: false}} }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt
        }
      }
    }
  }
`;

export default Index;
